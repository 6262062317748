import React from "react"
import { Link } from "gatsby"

import BaseTemplate from "../../components/templates/BaseTemplate"

const IndexPage = () => (
  <BaseTemplate title={"도냐 키히데와 건초 판사의 비망록"}>
    <div style={{ textAlign: `center` }}>
      <h3>도냐 키히데와 건초 판사의 비망록</h3>
      <h4>라블레 또는 스턴 풍으로 쓴 책방의 모험일지</h4>
      <br />
      <br />
      <p>
        <Link style={{ color: `black`, fontWeight: `bold` }} to={`email-list`}>
          지금까지의 모험 살펴보기
        </Link>
        <div style={{ height: `0.7rem` }}></div>
        <a
          style={{ color: `black`, fontWeight: `bold` }}
          href="https://page.stibee.com/subscriptions/331188"
        >
          후원하기
        </a>
      </p>
    </div>
    <br />
    <p>
      존경하는
      <br />
      후원자님 귀하
      <br />
      <br />
      <br />
      선생님, 이 헌사는 마포구 염리동 어드매에 있는 어느 한적한 책방에서 작성한
      것이고, 저는 그곳에서 여러 인생사의 고통을 유쾌한 웃음을 통해 떨쳐 내려고
      노력하는 건전한 사회 구성원일 따름입니다.
      <br />
      제가 공손히 청하고자 하는 것은 선생님께서 하루 일과를 마치고 여가 시간에
      우리의 모험기를 기억해주십사 하는 겁니다. 아, 꼭 여가 시간일 필요는
      없겠지요. 해야 할 일이 산더미처럼 쌓여서 어디론가 도피하고 싶은 순간에도
      나름 쏠쏠한 재미를 드릴 수 있으면 좋겠습니다. 저희가 일주일에 세 번
      보내드릴 글이 선생님을 웃게 하거나 한순간이라도 피로가 씻겨져 나가거나
      권태를 떨치게 했다는 소리를 듣는다면 그저 행복할 것입니다.
      <br />
      <br />
      사실상 우리의 모험일지가 세상에 제법 파장을 일으킬 것 같다는 예감이
      듭니다. 직감이 맞다면, 독서가와 독서가가 아닌 좋은 사람들까지 전부 독자로
      끌어들일 것이고, 온갖 베스트셀러 못지않게 널리 읽힐 것이고..., 그리하여
      우리가 받은 후원이 끊이지 않아서 서점극장 라블레가 문을 닫지 않고,
      서점지기들이 목구멍에 풀칠을 듬뿍하고, 서점의 도서 담당부가 외서와 온갖
      이상한 책들을 마구 입고하고, 작가들과 예술가들을 후원하며, 매일 밤 온갖
      마술 같은 연극이 펼쳐지고, 라블레문고라는 이름으로 출판조합까지 세워서
      마땅히 있으면 좋을 고전들과 재미와 감동, 새로움으로 가득찬 선집들을ㅡ
      값비싼 종이를 아낌없이 사용하여 우리 시대의 훌륭한 디자이너들의 손을 거쳐
      출간하는, 그런 마법 같은 일이 실제로 일어나지 않을지! 그래서 거의 눈코뜰새
      없이 바빠지지 않으려나.... 단지 그것만이 아주 약간 걱정되는군요. 아아, 저
      멀리 우유 한 통을 머리에 지고가는 소녀가 보이네요.
      <br />
      <br />
      물론 그렇다고 해서 선생님께 우리를 계속, 두둑히 후원해 달라고 요청하는
      것은 아닙니다. 요크 주의 어느 목사가 남긴 명설교에 이런 말씀이 있지요. "이
      책을 보호해 달라고 청하는 것은 전혀 아닙니다. 이 책 스스로 자신을 보호할
      수 있어야지요." 우리가 연재할 이야기 또한 그 스스로 자신을 보호할 수
      있기를 바랍니다. 혹자는 책방에 그저 앉아서 무슨 '모험'이냐고 할지
      모르겠지만, 모름지기 모험이란 한밤중에 내 방에서도 홀로 할 수 있는 법
      아니겠습니까. 하지만 우리 책방 모험일지에는 조금 더 다양한 사람들과 그보다
      더 많고 다양한 책의 인물들과 작가들이 나온답니다. 곧 시작될 페이지를
      기다리며, 다들 언제 출연하려나 하고 눈을 빛내고 있지요. 우리의 모험이
      어디로 향하게 될지, 그 전모는 조금 더 먼 훗날에 한낮의 태양 아래서처럼
      환하게 밝혀질 겁니다. "도대체 이게 모두 무슨 소리지요? ------- 수탉과 황소
      이야기지요"
      <br />
      <br />
      <br />
      위대하신 선생님,
      <br />
      좋은 분이신 선생님,
      <br />
      선생님의 안녕을 비는,
      <br />
      요즘 시대 보기 드물게 충직하고 겸손한 하인 드림
    </p>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
  </BaseTemplate>
)

export default IndexPage
